import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React from "react";
import { NewTheme } from "../../Theme/Theme";
import { BoldText, FlexItem } from "./Styled";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";

const php = new Php();

export default function MultiplierModal(props) {
  const [minMultiplier, setMinMultiplier] = React.useState(
    props.min_multiplier ? props.min_multiplier : 0
  );
  const [maxMultiplier, setMaxMultiplier] = React.useState(
    props.max_multiplier ? props.max_multiplier : 0
  );
  const [superMultiplier, setSuperMultiplier] = React.useState([]);
  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    load_super_multiplier();
  }, []);

  const load_super_multiplier = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        a_id: localStorage.getItem("adminsId"),
        m_id: props.master_id,
      };

      php.load_super_multiplier(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setSuperMultiplier(r.supers_multiplier);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  function handleMastersMultiplier() {
    if (
      minMultiplier < superMultiplier?.min_multiplier ||
      minMultiplier > superMultiplier?.max_multiplier
    ) {
      addNotification(
        "Enter Minimum Multiplier Between" +
          superMultiplier?.min_multiplier +
          " & " +
          superMultiplier?.max_multiplier,
        "error"
      );
    } else if (
      maxMultiplier < superMultiplier?.min_multiplier ||
      maxMultiplier > superMultiplier?.max_multiplier
    ) {
      addNotification(
        "Enter Maximum Multiplier Between" +
          superMultiplier?.min_multiplier +
          " & " +
          superMultiplier?.max_multiplier,
        "error"
      );
    } else if (maxMultiplier < minMultiplier) {
      addNotification(
        "Enter Maximum Multiplier More Than " + minMultiplier,
        "error"
      );
    } else {
      if (!loading) {
        setLoading(true);
        let data = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          a_id: localStorage.getItem("adminsId"),
          m_id: props.master_id,
          min_multiplier: minMultiplier,
          max_multiplier: maxMultiplier,
        };

        php.change_masters_multiplier(data).then((r) => {
          setLoading(false);
          if (r.error === "False") {
            props.load_master_overview();
            addNotification(r.message, "success");
          } else {
            addNotification(r.message, "error");
          }
        });
      }
    }
  }

  const footerContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => props.setVisible(false)}
        className="p-button-text"
        style={{ color: NewTheme.MainColor }}
      />
      <Button
        label="Update"
        icon="pi pi-check"
        onClick={() => handleMastersMultiplier(minMultiplier, maxMultiplier)}
        autoFocus
        style={{ backgroundColor: NewTheme.MainColor }}
      />
    </div>
  );
  return (
    <Dialog
      header={<div style={{ color: NewTheme.MainColor }}>Edit Multiplier</div>}
      visible={props.visible}
      position={props.position}
      style={{ width: "35vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => props.setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <FlexItem style={{ marginTop: 20 }}>
        <BoldText>Minimun Allowed</BoldText>
      </FlexItem>
      <div className="p-inputgroup flex-1" style={{ height: 40 }}>
        <span className="p-inputgroup-addon">
          {superMultiplier?.min_multiplier}
        </span>
        <InputText
          value={minMultiplier}
          onChange={(e) => setMinMultiplier(e.target.value)}
          placeholder="Enter Multiplier"
        />
        <span className="p-inputgroup-addon">
          {superMultiplier?.max_multiplier}
        </span>
      </div>

      <FlexItem style={{ marginTop: 20 }}>
        <BoldText>Maximun Allowed</BoldText>
      </FlexItem>
      <div className="p-inputgroup flex-1" style={{ height: 40 }}>
        <span className="p-inputgroup-addon">
          {superMultiplier?.min_multiplier}
        </span>
        <InputText
          value={maxMultiplier}
          onChange={(e) => setMaxMultiplier(e.target.value)}
          placeholder="Enter Multiplier"
        />
        <span className="p-inputgroup-addon">
          {superMultiplier?.max_multiplier}
        </span>
      </div>
    </Dialog>
  );
}
