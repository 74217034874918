import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";

import "jspdf-autotable";
import moment from "moment/moment";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import Php from "../../Backend/Php";
import TopbarMost from "../Common/TopbarMost";
import { useNotification } from "../Notification/NotificationContext";
import { NewTheme } from "../Theme/Theme";
import AddRuleModal from "./Modal/AddRuleModal";
import AddNotificationModal from "./Modal/AddNotificationModal";
import EditNotificationModal from "./Modal/EditNotificationModal";
const php = new Php();

const height = window.innerHeight;

export default function AdminNotification() {
  const [transactionData, setTransactionData] = useState([]);
  const [editModalData, setEditModalData] = useState([]);

  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);

  const [date, setDate] = useState([new Date(), new Date()]);

  const [filters, setFilters] = useState({
    symbol: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  useEffect(() => {
    load_god_notification();
  }, []);

  const load_god_notification = async (clear) => {
    try {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
      };

      php.load_god_notification(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          setTransactionData(r.rules);
        } else {
          addNotification(r.message, "error");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const deactivate_notification = (rowData) => {
    if (window.confirm("Do you really want to delete this Notification?")) {
      try {
        setLoading(true);
        let data = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          g_id: localStorage.getItem("godsId"),
          r_id: rowData.id,
          server: rowData.server,
        };

        php.deactive_god_notification(data).then((r) => {
          setLoading(false);
          if (r.error == "False") {
            load_god_notification();
            addNotification(r.message, "success");
          } else {
            addNotification(r.message, "error");
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const [filtersGlobal, setFiltersGlobal] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const value = filtersGlobal["global"] ? filtersGlobal["global"].value : "";
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filtersGlobal };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  const filterbar = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 10,
        fontWeight: "bold",
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 20,
        }}
      >
        <InputText
          style={{ width: 140 }}
          type="search"
          value={value || ""}
          onChange={(e) => onGlobalFilterChange(e)}
          placeholder="Search"
        />
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Button
          type="button"
          icon="pi pi-plus"
          severity="success"
          label="ADD"
          style={{
            height: 25,
            // width: 30,
            fontSize: 12,
            padding: 10,
          }}
          // onClick={exportExcel}
          onClick={() => show()}
          data-pr-tooltip="XLS"
        />
      </div>
    </div>
  );

  const [visible, setVisible] = React.useState(false);
  const [visibleEdit, setVisibleEdit] = React.useState(false);
  const [position, setPosition] = React.useState("top");

  const show = (deleteData) => {
    setVisible(true);
  };
  const showEdit = (editData) => {
    setVisibleEdit(true);
    setEditModalData(editData);
  };

  return (
    <div
      className="card"
      style={{
        // background: "black",
        // padding: "0.5rem",
        paddingTop: 0,
        // borderRadius: "10px",
        // marginBottom: "1rem",
        overflow: "hidden",
      }}
    >
      <TopbarMost name="Notifications" cmp={filterbar} />

      <div id={"stick"} style={{ position: "relative" }}>
        <DataTable
          removableSort
          stripedRows
          showGridlines
          scrollable
          scrollHeight={height - 150}
          size="small"
          filters={filters}
          // filterDisplay="row"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          value={transactionData}
          style={{ fontWeight: "bold", fontSize: 14 }}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginator
          rows={10}
          loading={loading}
          rowsPerPageOptions={[10, 25, 50, 100]}
        >
          <Column field="server" showFilterMenu={false} header="Server" />
          <Column field="news_type" showFilterMenu={false} header="Header" />
          <Column
            field="news"
            style={{ wordWrap: "break-word", width: "70%" }}
            showFilterMenu={false}
            header="Notification"
          />
          <Column
            field="news_start"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header="Start "
            body={(rowData) => (
              <div
                style={{
                  color:
                    moment(rowData.news_start).diff(moment(new Date())) >= 0
                      ? // moment(new Date()).format("DD-MMM-YY hh:mm:ss")
                        NewTheme.greencolor
                      : NewTheme.redcolor,
                }}
              >
                {moment(rowData.news_start).format("DD-MMM-YY ")}
                <br />
                <span style={{ color: NewTheme.MainColor }}>
                  {moment(rowData.news_start).format("HH:mm:ss")}
                </span>
              </div>
            )}
            sortable
          />
          <Column
            field="news_expiry"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header="Expiry "
            body={(rowData) => (
              <div
                style={{
                  color:
                    moment(rowData.news_expiry).diff(moment(new Date())) >= 0
                      ? // moment(new Date()).format("DD-MMM-YY hh:mm:ss")
                        NewTheme.greencolor
                      : NewTheme.redcolor,
                }}
              >
                {moment(rowData.news_expiry).format("DD-MMM-YY ")}
                <br />
                <span style={{ color: NewTheme.MainColor }}>
                  {moment(rowData.news_expiry).format("HH:mm:ss")}
                </span>
              </div>
            )}
            sortable
          />
          <Column
            field="date_created"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header="Date/Time"
            body={(rowData) => (
              <div>
                {moment(rowData.date_created).format("DD-MMM-YY ")}
                <br />
                <span style={{ color: NewTheme.MainColor }}>
                  {moment(rowData.date_created).format("HH:mm:ss")}
                </span>
              </div>
            )}
            sortable
          />
          <Column
            field="date_created"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header=""
            body={(rowData) => (
              <div style={{ display: "flex", gap: 5 }}>
                <Button
                  type="button"
                  icon={"pi pi-pencil"}
                  severity={"info"}
                  style={{ height: 25, width: 25 }}
                  onClick={() => showEdit(rowData)}
                  data-pr-tooltip="PDF"
                />
                <Button
                  type="button"
                  icon={rowData.status == 0 ? "pi pi-check" : "pi pi-times"}
                  severity={rowData.status == 0 ? "success" : "danger"}
                  style={{ height: 25, width: 25 }}
                  onClick={() => deactivate_notification(rowData)}
                  data-pr-tooltip="PDF"
                />
              </div>
            )}
          />
        </DataTable>
      </div>
      {visible && (
        <AddNotificationModal
          key={new Date()}
          setVisible={() => setVisible(false)}
          position={position}
          visible={visible}
          reload={() => {
            load_god_notification();
          }}
        />
      )}
      {visibleEdit && (
        <EditNotificationModal
          key={new Date()}
          setVisible={() => setVisibleEdit(false)}
          position={position}
          visible={visibleEdit}
          data={editModalData}
          reload={() => {
            load_god_notification();
          }}
        />
      )}
    </div>
  );
}
