import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";

import Php from "../../../Backend/Php";

import { Dropdown } from "primereact/dropdown";
import { NewTheme } from "../../Theme/Theme";
import {
  BalanceContainer,
  BalanceInput,
  BalanceLabel,
  BalanceWrapper,
} from "../../Users/Add/AddUser";

const php = new Php();

export default function EditModal(props) {
  const [aliasName, setAliasName] = React.useState("");
  const [groupName, setGroupName] = React.useState("");
  const [groupDetail, setGroupDetail] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    load_group_bill_entry();
  }, []);

  const load_group_bill_entry = () => {
    setLoading(true);
    let data = {
      sr: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      g_id: localStorage.getItem("godsId"),
      e_id: props.billSendId,
    };

    php.load_group_bill_entry(data).then((r) => {
      if (r.error == "False") {
        setAliasName(r.bill_send.alias_name);
        setGroupDetail(r.bill_send.group_detail);
        setGroupName(r.bill_send.group_name);
      } else {
        window.alert(r.message);
      }
      setLoading(false);
    });
  };

  const editBillSendEntry = (e) => {
    if (aliasName == "") {
      window.alert("Enter Alias Name");
    } else if (groupDetail == "") {
      window.alert("Enter Group Detail");
    } else {
      if (!loading) {
        setLoading(true);
        let data = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          g_id: localStorage.getItem("godsId"),
          e_id: props.billSendId,
          alias_name: aliasName,
          group_detail: groupDetail,
        };

        php.edit_group_bill_entry(data).then((r) => {
          if (r.error === "False") {
            load_group_bill_entry();
            props.setVisible(false);
            window.alert(r.message);
            props.reload();
          } else {
            window.alert(r.message);
          }
          setLoading(false);
        });
      }
    }
  };

  const footerContent = (
    <div>
      <Button
        label="Close"
        icon="pi pi-times"
        onClick={() => props.setVisible(false)}
        className="p-button-text"
        style={{ color: NewTheme.MainColor }}
      />

      <Button
        label="Update"
        icon="pi pi-pencil"
        onClick={() => editBillSendEntry()}
        style={{ backgroundColor: NewTheme.MainColor }}
      />
    </div>
  );
  return (
    <Dialog
      header={
        <div style={{ color: NewTheme.MainColor }}>
          {"Edit Bill Send Entry"}
        </div>
      }
      visible={props.visible}
      position={props.position}
      style={{ width: "50vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => props.setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <>
        {!loading && (
          <BalanceWrapper>
            <BalanceContainer>
              <BalanceLabel> Alias Name </BalanceLabel>
              <BalanceInput
                type="text"
                onChange={(e) => setAliasName(e.target.value)}
                value={aliasName}
                placeholder="Enter Alias Name "
              />
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel> Group Name </BalanceLabel>
              <BalanceInput
                type="text"
                onChange={(e) => setGroupName(e.target.value)}
                value={groupName}
                placeholder="Enter Group Name "
                disabled
              />
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel> Group Detail </BalanceLabel>
              <BalanceInput
                type="text"
                onChange={(e) => setGroupDetail(e.target.value)}
                value={groupDetail}
                placeholder="Enter Group Detail "
              />
            </BalanceContainer>
          </BalanceWrapper>
        )}
      </>
    </Dialog>
  );
}
