import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";

import Php from "../../../Backend/Php";

import { Dropdown } from "primereact/dropdown";
import { NewTheme } from "../../Theme/Theme";
import {
  BalanceContainer,
  BalanceInput,
  BalanceLabel,
  BalanceWrapper,
} from "../../Users/Add/AddUser";

const php = new Php();

export default function SerialModal(props) {
  const [serial, setSerial] = React.useState(props.billSendSerial.serial);
  const [loading, setLoading] = React.useState(false);

  const editBillSendEntry = (e) => {
    if (serial < 0) {
      window.alert("Enter Serial greater than 0");
    } else {
      if (!loading) {
        setLoading(true);
        let data = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          g_id: localStorage.getItem("godsId"),
          e_id: props.billSendSerial.id,
          serial: serial,
        };

        console.log(data);

        php.edit_bill_send_serial(data).then((r) => {
          console.log(r);
          setLoading(false);
          if (r.error === "False") {
            // load_bill_send_entry();
            props.setVisible(false);
            window.alert(r.message);
            props.reload();
            // props.setVisible(false);
          } else {
            window.alert(r.message);
          }
        });
      }
    }
  };

  const footerContent = (
    <div>
      <Button
        label="Close"
        icon="pi pi-times"
        onClick={() => props.setVisible(false)}
        className="p-button-text"
        style={{ color: NewTheme.MainColor }}
      />

      <Button
        label="Add"
        icon="pi pi-plus"
        onClick={() => editBillSendEntry()}
        style={{ backgroundColor: NewTheme.MainColor }}
      />
    </div>
  );
  return (
    <Dialog
      header={
        <div style={{ color: NewTheme.MainColor }}>
          {"Edit Bill Send Serial"}
        </div>
      }
      visible={props.visible}
      position={props.position}
      style={{ width: "50vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => props.setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <>
        <BalanceWrapper>
          <BalanceContainer>
            <BalanceLabel> Serial </BalanceLabel>
            <BalanceInput
              type="text"
              onChange={(e) => setSerial(e.target.value)}
              value={serial}
              placeholder="Enter Serial "
            />
          </BalanceContainer>
        </BalanceWrapper>
      </>
    </Dialog>
  );
}
