import styled, { css } from "styled-components";
import { NewTheme } from "../../Theme/Theme";

export const Wrapper = styled.div`
  border-bottom: 1px solid lightgray;
  margin-bottom: 20px;
  background-color: ${(props) => (props.e_lock != 0 ? "#da0c0c30" : "#ffffff")};
  padding: ${(props) => (props.e_lock == 0 ? "0px" : "5px")};
`;

export const FlexContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  text-align: left;
`;

export const FlexItem = styled.div`
  flex: 1 1 0%;
`;

export const GrayText = styled.div`
  color: gray;
  margin-bottom: 5px;
`;

export const BoldText = styled.div`
  font-weight: bold;
`;

export const BlueText = styled.div`
  color: ${NewTheme.MainColor};
  font-weight: bolder;
`;

export const GreenText = styled.div`
  color: rgb(92, 154, 93);
  font-weight: bolder;
`;

export const BlackText = styled.div`
  color: black;
  font-weight: bolder;
`;

export const ButtonGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem; /* Adjust the gap as needed (1rem is used as an example) */
  margin: 20px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const CreateClientButton = styled.button`
  padding: 8px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background 0.3s ease;

  ${(props) =>
    props.active
      ? css`
          border: 1px solid ${props.color ? props.color : "#3959ab"};
          background-color: ${props.color ? props.color : "#3959ab"};
          color: #fff;
        `
      : css`
          background: none;
          color: ${props.color ? props.color : "#3959ab"};
          border: 1px solid ${props.color ? props.color : "#3959ab"};
        `}
`;
