import React from "react";
import { Sidebar } from "primereact/sidebar";
import styled from "styled-components"; // Import styled-components for styling
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../Assets/Images/logo.png";
import { useSidebar } from "./SidebarContext";

import {
  faHome,
  faUser,
  faSitemap,
  faCrown,
  faUserCircle,
  faChartPie,
  faChartBar,
  faHandHoldingUsd,
  faWarehouse,
  faEye,
  faUserTie,
  faKey,
  faGavel,
  faBell,
} from "@fortawesome/free-solid-svg-icons";

import "./Mysidebar.css";
import { useLocation, useNavigate } from "react-router-dom";
import { NewTheme } from "../Theme/Theme";
const SidebarContainer = styled.div`
  background-color: ${NewTheme.MainColor};
  color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 100%; */
  padding: 1rem;
`;

const SidebarItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem 0;
  border: 1px solid transparent;
  transition: border 0.3s, transform 0.3s, color 0.3s, background-color 0.3s,
    border-radius 0.3s;
  background-color: transparent;
  box-sizing: border-box;
  margin-top: 5px;
  svg {
    color: ${NewTheme.lightmain};
    margin-right: 0.5rem;
    transition: color 0.3s;
    padding-left: 10px;
  }

  &:hover {
    border: 1px solid ${NewTheme.MainColor};
    background-color: white;
    color: ${NewTheme.MainColor};
    border-radius: 4px;

    svg {
      color: ${NewTheme.MainColor};
    }
  }

  &.active {
    border: 1px solid ${NewTheme.MainColor};
    background-color: white;
    color: ${NewTheme.MainColor};
    border-radius: 4px;

    svg {
      color: ${NewTheme.MainColor};
    }
  }
`;

const MySidebar = () => {
  const { isOpen, toggleSidebar } = useSidebar();

  const logoutFunc = () => {
    localStorage.clear();
    window.location = "/";
  };

  const customHeader = (
    <div
      style={{
        // height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div style={{ width: 60, height: 60 }}>
        <img src={logo} style={{ width: 60, height: 60 }} />
      </div>
      <div
        style={{
          marginLeft: 10,
          fontWeight: "bold",
          color: NewTheme.MainColor,
          fontSize: 20,
        }}
      >
        {localStorage.getItem("server").toUpperCase()}
        <br />
        <span style={{ fontSize: 16, color: "black", marginTop: -3 }}>
          {localStorage.getItem("godsUsername")?.toUpperCase()}
        </span>
      </div>
    </div>
  );

  const customIcons = (
    <React.Fragment>
      <button
        onClick={() => logoutFunc()}
        className="p-sidebar-icon p-link mr-2"
      >
        <span className="pi pi-power-off" />
      </button>
    </React.Fragment>
  );

  const menuItems = [
    { name: "Home", icon: faHome, route: "/home" },
    { name: "Watchlist", icon: faEye, route: "/watchlist" },
    { name: "Admin", icon: faCrown, route: "/admins" },
    { name: "Super", icon: faSitemap, route: "/supers" },
    { name: "Masters", icon: faCrown, route: "/masters" },
    { name: "Users", icon: faUserCircle, route: "/users" },
    // { name: "Brokers", icon: faUserTie, route: "/brokers" },

    { name: "Positions", icon: faChartPie, route: "/positions" },
    { name: "Holdings", icon: faChartPie, route: "/holdings" },
    { name: "Transactions", icon: faChartBar, route: "/transactions" },
    { name: "Billsend", icon: faChartBar, route: "/billsend" },
    { name: "Group Bill", icon: faChartBar, route: "/groupbill" },
    { name: "Scripts", icon: faChartBar, route: "/scripts" },
    // { name: "Profile", icon: faKey, route: "/profile" },
    { name: "Pendings", icon: faHandHoldingUsd, route: "/pendings" },
    // { name: "Brokerage", icon: faWarehouse, route: "/brokerage" },

    { name: "Rules", icon: faGavel, route: "/rules" },
    { name: "Notifications", icon: faBell, route: "/notifications" },
    { name: "Closing", icon: faBell, route: "/closing" },
    { name: "Ip Report", icon: faBell, route: "/ipreport" },
    {
      name: "Deactive Accounts",
      icon: faBell,
      route: "/deactiveaccountreport",
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();

  const handleMenuItemClick = (item) => {
    navigate(item.route);
    toggleSidebar();
  };

  return (
    <Sidebar
      visible={isOpen}
      onHide={toggleSidebar}
      style={{ padding: 0, margin: 0 }}
      closeOnEscape={true}
      icons={customIcons}
      header={customHeader}
    >
      <SidebarContainer>
        {menuItems.map((item, index) => (
          <SidebarItem
            key={index}
            onClick={() => handleMenuItemClick(item)}
            className={location.pathname === item.route ? "active" : ""}
          >
            <FontAwesomeIcon icon={item.icon} size="lg" />
            <span>{item.name}</span>
          </SidebarItem>
        ))}
        {/* Add more items as needed */}
      </SidebarContainer>
    </Sidebar>
  );
};

export default MySidebar;
