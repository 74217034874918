const api_link = "https://testapi.dreamtrade.biz/god";
const access_key =
  "040ec1ee950ffc53291f6df0ffc30325-a1d41284cbad95a80f076eed39859381.77004ea213d5fc71acf74a8c9c6795fb";

export default class Php {
  load_dashboard(data) {
    return fetch(api_link + "/load_dashboard", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  clear_all_group(data) {
    return fetch(api_link + "/clear_all_group", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_group_filter(data) {
    return fetch(api_link + "/load_group_filter", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  edit_group_bill_entry(data) {
    return fetch(api_link + "/edit_group_bill_entry", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_group_bill_entry(data) {
    return fetch(api_link + "/load_group_bill_entry", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  delete_group_bill_entry(data) {
    return fetch(api_link + "/delete_group_bill_entry", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_group(data) {
    return fetch(api_link + "/add_group", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_group_bill(data) {
    return fetch(api_link + "/load_group_bill", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_overall_pl(data) {
    return fetch(api_link + "/load_overall_pl", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_admins_unlock(data) {
    return fetch(api_link + "/change_admins_unlock", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_supers_unlock(data) {
    return fetch(api_link + "/change_supers_unlock", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_users_unlock(data) {
    return fetch(api_link + "/change_users_unlock", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_edit_admin(data) {
    return fetch(api_link + "/load_edit_admin", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_edit_master(data) {
    return fetch(api_link + "/load_edit_master", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_edit_super(data) {
    return fetch(api_link + "/load_edit_super", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_edit_brokers(data) {
    return fetch(api_link + "/load_edit_brokers", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_master_brokerages(data) {
    return fetch(api_link + "/load_master_brokerages", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_masters_unlock(data) {
    return fetch(api_link + "/change_masters_unlock", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_deactive_account(data) {
    return fetch(api_link + "/load_deactive_account", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_ip_report(data) {
    return fetch(api_link + "/load_ip_report", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  update_fut_closing(data) {
    return fetch(api_link + "/update_fut_closing", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  pass_pending(data) {
    return fetch(api_link + "/pass_pending", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  stop_all_pending(data) {
    return fetch(api_link + "/stop_all_pending", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  cancel_users_pending(data) {
    return fetch(api_link + "/cancel_users_pending", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  stop_all_trades(data) {
    return fetch(api_link + "/stop_all_trades", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  update_fo_closing(data) {
    return fetch(api_link + "/update_fo_closing", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  get_fut_closing_status(data) {
    return fetch(api_link + "/get_fut_closing_status", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  get_fo_closing_status(data) {
    return fetch(api_link + "/get_fo_closing_status", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_admins_active_summary(data) {
    return fetch(api_link + "/load_admins_active_summary", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_god_multiplier(data) {
    return fetch(api_link + "/load_god_multiplier", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_admin(data) {
    return fetch(api_link + "/add_admin", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_admin_overview(data) {
    return fetch(api_link + "/load_admin_overview", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_admins_summary(data) {
    return fetch(api_link + "/load_admins_summary", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_super_multiplier(data) {
    return fetch(api_link + "/load_super_multiplier", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_admins(data) {
    return fetch(api_link + "/load_admins", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_gods_watchlist(data) {
    return fetch(api_link + "/load_gods_watchlist", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  edit_super_brokerage(data) {
    return fetch(api_link + "/edit_super_brokerage", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_supers_brokerages(data) {
    return fetch(api_link + "/load_supers_brokerages", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  edit_super(data) {
    return fetch(api_link + "/edit_super", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_supers_data(data) {
    return fetch(api_link + "/load_supers_data", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_admin_brokerages(data) {
    return fetch(api_link + "/load_admin_brokerages", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_super(data) {
    return fetch(api_link + "/add_super", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_supers_multiplier(data) {
    return fetch(api_link + "/change_supers_multiplier", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_admin_multiplier(data) {
    return fetch(api_link + "/load_admin_multiplier", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_super_overview(data) {
    return fetch(api_link + "/load_super_overview", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  edit_admin(data) {
    return fetch(api_link + "/edit_admin", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_admins_delete_transaction(data) {
    return fetch(api_link + "/change_admins_delete_transaction", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_admins_manual_trade(data) {
    return fetch(api_link + "/change_admins_manual_trade", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_admins_setting(data) {
    return fetch(api_link + "/change_admins_setting", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_admins_login(data) {
    return fetch(api_link + "/load_admins_login", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  closing_step_one(data) {
    return fetch(api_link + "/closing_step_one", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  closing_step_two(data) {
    return fetch(api_link + "/closing_step_two", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  full_backup(data) {
    return fetch(api_link + "/full_backup", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  run_settlement(data) {
    return fetch(api_link + "/run_settlement", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  client_backup(data) {
    return fetch(api_link + "/client_backup", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  run_closing(data) {
    return fetch(api_link + "/run_closing", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  get_mcx_closing(data) {
    return fetch(api_link + "/get_mcx_closing", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  update_mcx_rate(data) {
    return fetch(api_link + "/update_mcx_rate", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  update_rates_position(data) {
    return fetch(api_link + "/update_rates_position", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  get_position_rates(data) {
    return fetch(api_link + "/get_position_rates", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_masters_balance(data) {
    return fetch(api_link + "/change_masters_balance", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  god_manual_trade(data) {
    return fetch(api_link + "/god_manual_trade", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  deactive_god_rules(data) {
    return fetch(api_link + "/deactive_god_rules", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_god_rules(data) {
    return fetch(api_link + "/add_god_rules", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_god_rules(data) {
    return fetch(api_link + "/load_god_rules", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  edit_god_notification(data) {
    return fetch(api_link + "/edit_god_notification", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_bill_send(data) {
    return fetch(api_link + "/load_bill_send", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  get_all_fut_scripts(data) {
    return fetch(api_link + "/get_all_fut_scripts", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_admins_ledger(data) {
    return fetch(api_link + "/load_admins_ledger", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_admins_scripts(data) {
    return fetch(api_link + "/load_admins_scripts", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  get_all_mcx_scripts(data) {
    return fetch(api_link + "/get_all_mcx_scripts", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  edit_admin_brokerage(data) {
    return fetch(api_link + "/edit_admin_brokerage", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_admins_brokerages(data) {
    return fetch(api_link + "/load_admins_brokerages", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_god_brokerages(data) {
    return fetch(api_link + "/load_god_brokerages", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_admins_multiplier(data) {
    return fetch(api_link + "/change_admins_multiplier", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_admins_data(data) {
    return fetch(api_link + "/load_admins_data", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_admins_status(data) {
    return fetch(api_link + "/change_admins_status", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_supers_status(data) {
    return fetch(api_link + "/change_supers_status", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_supers_active_summary(data) {
    return fetch(api_link + "/load_supers_active_summary", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_supers_setting(data) {
    return fetch(api_link + "/change_supers_setting", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_god_notification(data) {
    return fetch(api_link + "/add_god_notification", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  deactive_god_notification(data) {
    return fetch(api_link + "/deactive_god_notification", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_god_notification(data) {
    return fetch(api_link + "/load_god_notification", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  ban_supers_script(data) {
    return fetch(api_link + "/ban_supers_script", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  unban_supers_script(data) {
    return fetch(api_link + "/unban_supers_script", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_supers_scripts(data) {
    return fetch(api_link + "/load_supers_scripts", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_supers_login(data) {
    return fetch(api_link + "/load_supers_login", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_supers_ledger(data) {
    return fetch(api_link + "/load_supers_ledger", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_masters_multiplier(data) {
    return fetch(api_link + "/change_masters_multiplier", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_masters_active_summary(data) {
    return fetch(api_link + "/load_masters_active_summary", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_brokers_summary(data) {
    return fetch(api_link + "/load_brokers_summary", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_master(data) {
    return fetch(api_link + "/add_master", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  edit_master(data) {
    return fetch(api_link + "/edit_master", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_masters_data(data) {
    return fetch(api_link + "/load_masters_data", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_masters_ledger(data) {
    return fetch(api_link + "/load_masters_ledger", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_masters_scripts(data) {
    return fetch(api_link + "/load_masters_scripts", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_masters_status(data) {
    return fetch(api_link + "/change_masters_status", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_master_overview(data) {
    return fetch(api_link + "/load_master_overview", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_super_brokerages(data) {
    return fetch(api_link + "/load_super_brokerages", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_masters_summary(data) {
    return fetch(api_link + "/load_masters_summary", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_notification(data) {
    return fetch(api_link + "/load_notification", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  edit_bill_send_serial(data) {
    return fetch(api_link + "/edit_bill_send_serial", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  users_sqoff_single(data) {
    return fetch(api_link + "/users_sqoff_single", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_brokers_clients(data) {
    return fetch(api_link + "/load_brokers_clients", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_brokers_login(data) {
    return fetch(api_link + "/load_brokers_login", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  edit_broker(data) {
    return fetch(api_link + "/edit_broker", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_brokers_data(data) {
    return fetch(api_link + "/load_brokers_data", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  edit_user(data) {
    return fetch(api_link + "/edit_user", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_brokers(data) {
    return fetch(api_link + "/load_brokers", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  search_script(data) {
    return fetch(api_link + "/search_script", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_script(data) {
    return fetch(api_link + "/add_script", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_masters(data) {
    return fetch(api_link + "/load_masters", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_admins_watchlist(data) {
    return fetch(api_link + "/load_admins_watchlist", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_masters_setting(data) {
    return fetch(api_link + "/change_masters_setting", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_transactions_history(data) {
    return fetch(api_link + "/load_transactions_history", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  delete_script(data) {
    return fetch(api_link + "/delete_script", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_broker_client_filter(data) {
    return fetch(api_link + "/load_broker_client_filter", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_broker_filter(data) {
    return fetch(api_link + "/load_broker_filter", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_broker(data) {
    return fetch(api_link + "/add_broker", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_brokers_overview(data) {
    return fetch(api_link + "/load_brokers_overview", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_brokers_status(data) {
    return fetch(api_link + "/change_brokers_status", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  delete_transaction(data) {
    return fetch(api_link + "/delete_transaction", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  get_closing_status(data) {
    return fetch(api_link + "/get_closing_status", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  unlock_closing(data) {
    return fetch(api_link + "/unlock_closing", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  delete_users(data) {
    return fetch(api_link + "/delete_users", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  reverse_transaction(data) {
    return fetch(api_link + "/reverse_transaction", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_users(data) {
    return fetch(api_link + "/load_users", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_users: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_live_transaction(data) {
    return fetch(api_link + "/load_live_transaction", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_users_data(data) {
    return fetch(api_link + "/load_users_data", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_users_data: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  edit_users_scripts(data) {
    return fetch(api_link + "/edit_users_scripts", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  ban_users_script(data) {
    return fetch(api_link + "/ban_users_script", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_users_rejection(data) {
    return fetch(api_link + "/load_users_rejection", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_users_ledger(data) {
    return fetch(api_link + "/load_users_ledger", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_users_login(data) {
    return fetch(api_link + "/load_users_login", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  unban_masters_script(data) {
    return fetch(api_link + "/unban_masters_script", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_supers_summary(data) {
    return fetch(api_link + "/load_supers_summary", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_super_brokerages(data) {
    return fetch(api_link + "/load_super_brokerages", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_supers_brokerages(data) {
    return fetch(api_link + "/load_supers_brokerages", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_super_multiplier(data) {
    return fetch(api_link + "/load_super_multiplier", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_supers(data) {
    return fetch(api_link + "/load_supers", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  unban_users_script(data) {
    return fetch(api_link + "/unban_users_script", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  ban_masters_script(data) {
    return fetch(api_link + "/ban_masters_script", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  reset_users_scripts(data) {
    return fetch(api_link + "/reset_users_scripts", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        reset_users_scripts: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_users_data(data) {
    return fetch(api_link + "/add_users_data", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        add_users_data: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_user(data) {
    return fetch(api_link + "/add_user", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        add_user: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_users_scripts(data) {
    return fetch(api_link + "/load_users_scripts", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_users_scripts: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_positions(data) {
    return fetch(api_link + "/load_positions", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_positions: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_transactions(data) {
    return fetch(api_link + "/load_transactions", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_transactions: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_pendings(data) {
    return fetch(api_link + "/load_pendings", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_pendings: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_users_setting(data) {
    return fetch(api_link + "/change_users_setting", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        change_users_setting: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_users_limit(data) {
    return fetch(api_link + "/add_users_limit", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        add_users_limit: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_users_multiplier(data) {
    return fetch(api_link + "/change_users_multiplier", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        change_users_multiplier: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_users_balance(data) {
    return fetch(api_link + "/add_users_balance", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        add_users_balance: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_users_overview(data) {
    return fetch(api_link + "/load_users_overview", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_users_overview: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  change_users_status(data) {
    return fetch(api_link + "/change_users_status", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_users_lock(data) {
    return fetch(api_link + "/add_users_lock", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  cancel_users_lock(data) {
    return fetch(api_link + "/cancel_users_lock", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_users_fatak(data) {
    return fetch(api_link + "/add_users_fatak", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  cancel_users_fatak(data) {
    return fetch(api_link + "/cancel_users_fatak", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_users_locks(data) {
    return fetch(api_link + "/load_users_locks", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_users_fatak(data) {
    return fetch(api_link + "/load_users_fatak", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_users_summary(data) {
    return fetch(api_link + "/load_users_summary", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_brokerage(data) {
    return fetch(api_link + "/load_brokerage", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_online_users(data) {
    return fetch(api_link + "/load_online_users", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_users_active_summary(data) {
    return fetch(api_link + "/load_users_active_summary", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_parking(data) {
    return fetch(api_link + "/load_parking", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_all_scripts(data) {
    return fetch(api_link + "/add_all_scripts", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  ban_script(data) {
    return fetch(api_link + "/ban_script", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_bill_send_entry(data) {
    return fetch(api_link + "/load_bill_send_entry", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  bill_send_brokerage(data) {
    return fetch(api_link + "/bill_send_brokerage", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  edit_bill_send_entry(data) {
    return fetch(api_link + "/edit_bill_send_entry", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  delete_bill_send_entry(data) {
    return fetch(api_link + "/delete_bill_send_entry", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_bill_send_entry(data) {
    return fetch(api_link + "/add_bill_send_entry", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  get_all_scripts(data) {
    return fetch(api_link + "/get_all_scripts", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_holdings(data) {
    return fetch(api_link + "/load_holdings", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_holding_script(data) {
    return fetch(api_link + "/load_holding_script", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }
}
