import { Button } from "primereact/button";
import React, { useState } from "react";
import SocketContext from "../../SocketContext/SocketContext";
import moment from "moment/moment";
import { NewTheme } from "../Theme/Theme";
import { connect } from "react-redux";
import { useNotification } from "../Notification/NotificationContext";
import Php from "../../Backend/Php";
import { faL } from "@fortawesome/free-solid-svg-icons";

const php = new Php();

function PositionCard(props) {
  const i = props.item;

  const [loading, setLoading] = useState(false);
  const { addNotification } = useNotification();

  const socket = React.useContext(SocketContext);
  const [ask, setask] = useState(0);
  const [bid, setbid] = useState(0);
  // const [m2m, setm2m] = useState(0);
  // const [current, setcurrent] = useState(0);
  const [current, setcurrent] = useState(0);
  const [m2m, setm2m] = useState(0);
  const [broktot, setbroktot] = useState(0);
  const [pfl, setpfl] = useState(0);

  // const socketbidask = (msg) => {
  //   if (msg === null) {
  //   } else {
  //     if (bid !== msg.Bid) {
  //       setbid(msg.Bid);
  //     }
  //     if (ask !== msg.Ask) {
  //       setask(msg.Ask);
  //     }
  //     if (i.buy_sell == 0) {
  //       if (msg.Bid == 0) {
  //         setbid(msg.LTP);
  //       } else {
  //         setbid(msg.Bid);
  //       }
  //       var m2m2 = ((msg.Bid == 0 ? msg.LTP : msg.Bid) - i.rate) * i.qty;

  //       var brokersale = 0;
  //       var totalbroke = 0;
  //       var profitloss = 0;
  //       if (i.segment == 0) {
  //         brokersale =
  //           (i.qty * i.brokerage * (msg.Bid == 0 ? msg.LTP : msg.Bid)) / 100;
  //       } else {
  //         brokersale = (i.qty * i.brokerage) / i.lot_size;
  //       }
  //       totalbroke = brokersale;
  //       profitloss = m2m2 - brokersale;
  //       setm2m(m2m2);
  //       setbroktot(totalbroke);
  //       setpfl(profitloss);
  //     } else {
  //       if (msg.Ask == 0) {
  //         setbid(msg.LTP);
  //       } else {
  //         setbid(msg.Ask);
  //       }
  //       var m2m2 = (i.rate - (msg.Ask == 0 ? msg.LTP : msg.Ask)) * i.qty;

  //       var brokersale = 0;
  //       var totalbroke = 0;
  //       var profitloss = 0;
  //       if (i.segment == 0) {
  //         brokersale =
  //           (i.qty * i.brokerage * (msg.Ask == 0 ? msg.LTP : msg.Ask)) / 100;
  //       } else {
  //         brokersale = (i.qty * i.brokerage) / i.lot_size;
  //       }
  //       totalbroke = brokersale;
  //       profitloss = m2m2 - brokersale;
  //       setm2m(m2m2);
  //       setbroktot(totalbroke);
  //       setpfl(profitloss);
  //     }
  //   }
  // };

  const sockettrade = (msg) => {
    socket.off("trade" + i.script_id, sockettrade);
    if (msg === null) {
    } else {
      if (bid !== msg.Bid) {
        setbid(msg.Bid);
      }
      if (ask !== msg.Ask) {
        setask(msg.Ask);
      }

      if (i.buy_sell == 0) {
        if (msg.Bid == 0) {
          setbid(msg.LTP);
        } else {
          setbid(msg.Bid);
        }
        var m2m2 = ((msg.Bid == 0 ? msg.LTP : msg.Bid) - i.rate) * i.qty;

        var brokersale = 0;
        var totalbroke = 0;
        var profitloss = 0;
        if (i.segment == 0) {
          brokersale =
            (i.qty * i.brokerage * (msg.Bid == 0 ? msg.LTP : msg.Bid)) / 100;
        } else {
          brokersale = (i.qty * i.brokerage) / i.lot_size;
        }
        totalbroke = brokersale;
        profitloss = m2m2 - brokersale;
        setm2m(m2m2);
        setbroktot(totalbroke);
        setpfl(profitloss);
      } else {
        if (msg.Ask == 0) {
          setask(msg.LTP);
        } else {
          setask(msg.Ask);
        }
        var m2m2 = (i.rate - (msg.Ask == 0 ? msg.LTP : msg.Ask)) * i.qty;

        var brokersale = 0;
        var totalbroke = 0;
        var profitloss = 0;
        if (i.segment == 0) {
          brokersale =
            (i.qty * i.brokerage * (msg.Ask == 0 ? msg.LTP : msg.Ask)) / 100;
        } else {
          brokersale = (i.qty * i.brokerage) / i.lot_size;
        }
        totalbroke = brokersale;
        profitloss = m2m2 - brokersale;
        setm2m(m2m2);
        setbroktot(totalbroke);
        setpfl(profitloss);
      }
    }
  };

  React.useEffect(() => {
    socket.emit("giverate", i.script_id);

    socket.on("trade" + i.script_id, sockettrade);

    // socket.on("bidask" + i.script_id, socketbidask);

    return () => {
      socket.off("trade" + i.script_id, sockettrade);

      // socket.off("bidask" + i.script_id, socketbidask);
    };
  }, []);

  props.calc_pf({
    customer_id: i.users_id,
    id: i.id,
    m2m: m2m,
    broker: broktot,
    pfit: pfl,
    // buy: i.buy_sale == 0 ? parseFloat(i.qty * i.rate) : 0,
    // sell: i.buy_sale == 1 ? parseFloat(i.qty * i.rate) : 0,
  });

  const sqOffSingle = () => {
    var confirm = window.confirm(
      "Are Your Sure Want To Square Off " +
        i.symbol +
        " Positions of " +
        i?.username?.toUpperCase() +
        "?"
    );
    if (confirm) {
      if (!loading) {
        setLoading(true);
        let data = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          gc_id: localStorage.getItem("godsId"),
          p_id: i.id,
          c_id: i.users_id,
          s_id: i.script_id,
        };

        php.users_sqoff_single(data).then((r) => {
          setLoading(false);
          if (r.error === "False") {
            if (r.success === "True") {
              props.load_position();
              addNotification(r.message, "success");
            } else {
              addNotification(r.message, "error");
            }
          } else {
            addNotification(r.message, "error");
          }
        });
      }
    }
  };

  return (
    <div
      style={{
        height: 40,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: "lightgray",
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        paddingLeft: 10,
        fontWeight: "bolder",
        fontSize: 14,
      }}
    >
      <div
        style={{
          flex: 0.5,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
          flexDirection: "column",
          fontSize: 12,
        }}
      >
        {i.admin}
      </div>
      <div
        style={{
          flex: 0.5,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
          flexDirection: "column",
          fontSize: 12,
        }}
      >
        {i.super}
        <span style={{ borderTopWidth: 1, borderTopStyle: "solid" }}>
          {i.master}
        </span>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
        }}
      >
        {i.username}
      </div>
      <div
        style={{
          flex: 2,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
          flexDirection: "column",
          color: NewTheme.MainColor,
        }}
      >
        <span style={{ paddingLeft: 5 }}> {i.symbol}</span>
        <span
          style={{
            paddingLeft: 5,
            fontSize: 12,
            fontWeight: "bold",
            color: "gray",
          }}
        >
          {moment(i.expiry_date).format("DD-MMM")}
        </span>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
        }}
      >
        {i.buy_sell == 0 ? i.rate : 0}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
        }}
      >
        {i.buy_sell == 1 ? i.rate : 0}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
          color: i.buy_sell == 0 ? NewTheme.greencolor : NewTheme.redcolor,
        }}
      >
        {i.buy_sell == 0 ? "" : "-"} {parseInt(i.qty)}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
          color: NewTheme.MainColor,
        }}
      >
        {i.buy_sell == 0 ? bid : ask}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
          color: m2m >= 0 ? NewTheme.greencolor : NewTheme.redcolor,
        }}
      >
        {parseFloat(m2m).toFixed(1)}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
          color: NewTheme.redcolor,
        }}
      >
        {"-" + parseFloat(broktot).toFixed(0)}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
          color: pfl >= 0 ? NewTheme.greencolor : NewTheme.redcolor,
        }}
      >
        {parseFloat(pfl).toFixed(1)}
      </div>
      <div
        style={{
          flex: 0.5,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
        }}
      >
        <i
          className="pi pi-times"
          onClick={() => sqOffSingle()}
          style={{
            color: NewTheme.redcolor,
            fontSize: 18,
            width: "50%",
          }}
        ></i>
      </div>
    </div>
  );
}

const MapStateToProps = (state) => {
  return {
    theme: state.theme,
  };
};
const MapStateToDispatch = (dispatch) => {
  return {
    calc_pf: (r) => {
      dispatch({
        type: "CALC_PROFIT",
        data: r,
      });
    },
    // srScriptData: (a) => dispatch({ type: "SCRIPTDATA", payload: a }),
  };
};

export default connect(MapStateToProps, MapStateToDispatch)(PositionCard);
