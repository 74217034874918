import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";

import Php from "../../../Backend/Php";

import { Dropdown } from "primereact/dropdown";
import { NewTheme } from "../../Theme/Theme";
import {
  BalanceContainer,
  BalanceInput,
  BalanceLabel,
  BalanceWrapper,
} from "../../Users/Add/AddUser";

const php = new Php();

export default function EditModal(props) {
  const [groupArray, setGroupArray] = React.useState([]);
  const [selectedGroupArray, setSelectedGroupArray] = React.useState({});
  const [server, setServer] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [wa1, setWa1] = React.useState("");
  const [wa2, setWa2] = React.useState("");
  const [wa3, setWa3] = React.useState("");
  const [bhaag, setBhaag] = React.useState("unset");
  const [selectedType, setSelectedType] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const type = [
    { id: "brokersummary", name: "Broker Summary" },
    { id: "brokerbill", name: "Broker Bill" },
    { id: "masterbill", name: "Master Bill" },
    { id: "superbill", name: "Super Bill" },
    { id: "adminbill", name: "Admin Bill" },
  ];

  React.useEffect(() => {
    load_bill_send_entry();
  }, []);

  const load_bill_send_entry = () => {
    setLoading(true);
    let data = {
      sr: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      g_id: localStorage.getItem("godsId"),
      e_id: props.billSendId,
    };

    php.load_bill_send_entry(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        type.map((i, t) => {
          if (i.id == r.bill_send.type) {
            setSelectedType(i);
          }
        });
        r.get_group.map((i, t) => {
          if (i.id == r.bill_send.group_id) {
            setSelectedGroupArray(i);
          }
        });
        setGroupArray(r.get_group);
        setWa1(r.bill_send.wa1);
        setWa2(r.bill_send.wa2);
        setWa3(r.bill_send.wa3);
        setBhaag(r.bill_send.bhaag);
        setUsername(r.bill_send.username);
        setServer(r.bill_send.server);
      } else {
        window.alert(r.message);
      }
    });
  };

  const editBillSendEntry = (e) => {
    if (username == "") {
      window.alert("Enter Username");
    } else if (server == "") {
      window.alert("Enter Server");
    } else if (selectedType?.id == "" || selectedType?.id == undefined) {
      window.alert("Select Bill Type");
    } else {
      if (!loading) {
        setLoading(true);
        let data = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          g_id: localStorage.getItem("godsId"),
          e_id: props.billSendId,
          type: selectedType?.id,
          bhaag: bhaag,
          username: username,
          wa1: wa1,
          wa2: wa2,
          wa3: wa3,
          server: server,
          group_id:
            selectedGroupArray?.id == undefined ? 0 : selectedGroupArray?.id,
          group_array:
            selectedGroupArray?.id == undefined
              ? 0
              : selectedGroupArray?.group_name,
        };

        php.edit_bill_send_entry(data).then((r) => {
          setLoading(false);
          if (r.error === "False") {
            load_bill_send_entry();
            props.setVisible(false);
            window.alert(r.message);
            props.reload();
            // props.setVisible(false);
          } else {
            window.alert(r.message);
          }
        });
      }
    }
  };

  const footerContent = (
    <div>
      <Button
        label="Close"
        icon="pi pi-times"
        onClick={() => props.setVisible(false)}
        className="p-button-text"
        style={{ color: NewTheme.MainColor }}
      />

      <Button
        label="Update"
        icon="pi pi-pencil"
        onClick={() => editBillSendEntry()}
        style={{ backgroundColor: NewTheme.MainColor }}
      />
    </div>
  );
  return (
    <Dialog
      header={
        <div style={{ color: NewTheme.MainColor }}>
          {"Edit Bill Send Entry"}
        </div>
      }
      visible={props.visible}
      position={props.position}
      style={{ width: "50vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => props.setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <>
        <BalanceWrapper>
          <BalanceContainer>
            <BalanceLabel> Server </BalanceLabel>
            <BalanceInput
              type="text"
              onChange={(e) => setServer(e.target.value)}
              value={server}
              placeholder="Enter Server "
            />
          </BalanceContainer>
          <BalanceContainer>
            <BalanceLabel>Bill Type </BalanceLabel>
            <Dropdown
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minWidth: "50%",
                height: 30,
              }}
              showClear
              onChange={(e) => {
                console.log(e);
                if (e.value == undefined) {
                  setSelectedType({});
                } else {
                  setSelectedType(e.value);
                }
              }}
              value={selectedType}
              options={type}
              optionLabel="name"
              placeholder="Select Type"
              filter
            />
          </BalanceContainer>
          <BalanceContainer>
            <BalanceLabel> Username </BalanceLabel>
            <BalanceInput
              type="text"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              placeholder="Enter Username "
            />
          </BalanceContainer>
          <BalanceContainer>
            <BalanceLabel> WhatsApp No 1</BalanceLabel>
            <BalanceInput
              type="text"
              onChange={(e) => setWa1(e.target.value)}
              value={wa1}
              placeholder="Enter WhatsApp No 1 "
            />
          </BalanceContainer>
          <BalanceContainer>
            <BalanceLabel> WhatsApp No 2</BalanceLabel>
            <BalanceInput
              type="text"
              onChange={(e) => setWa2(e.target.value)}
              value={wa2}
              placeholder="Enter WhatsApp No 2 "
            />
          </BalanceContainer>
          <BalanceContainer>
            <BalanceLabel> WhatsApp No 3</BalanceLabel>
            <BalanceInput
              type="text"
              onChange={(e) => setWa3(e.target.value)}
              value={wa3}
              placeholder="Enter WhatsApp No 3 "
            />
          </BalanceContainer>
          <BalanceContainer>
            <BalanceLabel>Bhaag</BalanceLabel>
            <BalanceInput
              type="text"
              onChange={(e) => setBhaag(e.target.value)}
              value={bhaag}
              placeholder="Enter Bhaag "
            />
          </BalanceContainer>
          <BalanceContainer>
            <BalanceLabel>Group Name</BalanceLabel>
            <Dropdown
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minWidth: "50%",
                height: 30,
              }}
              showClear
              onChange={(e) => {
                if (e.value == undefined) {
                  setSelectedGroupArray({});
                } else {
                  setSelectedGroupArray(e.value);
                }
              }}
              value={selectedGroupArray}
              options={groupArray}
              optionLabel="group_name"
              placeholder="Select Group Array"
              filter
            />
          </BalanceContainer>
        </BalanceWrapper>
      </>
    </Dialog>
  );
}
