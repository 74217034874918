import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";

import * as ExcelJS from "exceljs";
import "jspdf-autotable";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import Php from "../../Backend/Php";
import NumberFormatIn from "../Common/NumberFormatIn";
import TopbarMost from "../Common/TopbarMost";
import { useNotification } from "../Notification/NotificationContext";
import { NewTheme } from "../Theme/Theme";
import AddModal from "./Modal/AddModal";
import EditModal from "./Modal/EditModal";
import moment from "moment";
const php = new Php();

const height = window.innerHeight;

export default function GroupBill() {
  const [billSend, setBillSend] = useState([]);
  const [billSendId, setBillSendId] = useState(0);

  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);

  const [filters, setFilters] = useState({
    symbol: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const [filtersGlobal, setFiltersGlobal] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const value = filtersGlobal["global"] ? filtersGlobal["global"].value : "";
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filtersGlobal };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  useEffect(() => {
    load_group_bill();
  }, []);

  const load_group_bill = () => {
    setLoading(true);
    let data = {
      sr: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      g_id: localStorage.getItem("godsId"),
    };

    php.load_group_bill(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setBillSend(r.group_bill);
      } else {
        addNotification(r.message, "error");
      }
    });
  };

  const clearGroupData = (id) => {
    if (window.confirm("Do you really want to clear all group data?")) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
      };

      php.clear_all_group(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          load_group_bill();
          addNotification(r.message, "success");
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const deleteEntry = (id) => {
    if (window.confirm("Do you really want to delete?")) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        e_id: id,
      };

      php.delete_group_bill_entry(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          load_group_bill();
          addNotification(r.message, "success");
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const exportExcel = () => {
    // Create a new Excel workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("data");

    // Specify the display names for the headers
    const headerDisplayNames = [
      "No",
      moment(new Date()).format("DD.MM.YY"),
      "CLIENT NAME",
      "TOTAL-INR",
    ];

    // Add the header row with separate text for each cell
    const headerRow = worksheet.addRow(["", "DREAM", "FO/MCX", "BILL"]);

    // Define common styles
    const alignmentStyle = { horizontal: "center", vertical: "middle" };
    const fontStyle = { bold: true };

    // Apply styles to each header cell from B to D
    ["B", "C", "D"].forEach((col) => {
      const cell = headerRow.getCell(col);
      cell.alignment = alignmentStyle;
      cell.font = fontStyle;
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "77bbd696" }, // Orange background color (ARGB format)
      };
    });

    // Commit the row after styling
    headerRow.commit();
    const headers = worksheet.addRow(headerDisplayNames);

    headers.eachCell((cell, colNumber) => {
      cell.font = {
        bold: true,
      };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "60A3D9" }, // Orange background color (ARGB format)
      };
      worksheet.getColumn(colNumber).alignment = {
        horizontal: "center",
        vertical: "middle", // Optional: Aligns text vertically in the middle
      };
    });

    // Extract only the desired columns from transactionData
    const colsToExport = ["no", "group_name", "client_name", "amount"];
    const filteredData = billSend.map((item) => {
      return colsToExport.reduce((acc, col) => {
        if (col === "buy_sell") {
          acc[col] = item[col] == 0 ? "Buy" : "Sell";
        } else {
          acc[col] = item[col];
        }
        return acc;
      }, {});
    });

    // Add the filtered data to the worksheet
    filteredData.forEach((data) => {
      worksheet.addRow(Object.values(data));
    });

    // Calculate and set the maximum width for each column
    worksheet.columns.forEach((column) => {
      let maxLength = 0;

      column.eachCell({ includeEmpty: true }, (cell) => {
        const cellValue = cell.value ? cell.value.toString() : "";
        maxLength = Math.max(maxLength, cellValue.length);
      });

      column.width = maxLength + 2; // Adjust width with some padding
    });

    const colIndex = colsToExport.indexOf("amount");
    worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
      colsToExport.forEach((col, colIndex) => {
        const cells = row.getCell(colIndex + 1);
        cells.font = {
          bold: true, // Bold font for header row
          color:
            col === "amount" && rowNumber > 2
              ? { argb: cells.value > 0 ? "FF00FF00" : "FFFF0000" }
              : undefined, // Green for positive, Red for negative amount cellss
        };

        cells.alignment = {
          horizontal: "center",
          vertical: "middle",
        };

        cells.border = {
          top: { style: "thin", color: { argb: "FF808080" } },
          left: { style: "thin", color: { argb: "FF808080" } },
          bottom: { style: "thin", color: { argb: "FF808080" } },
          right: { style: "thin", color: { argb: "FF808080" } },
        };
      });

      const cellValue = row.getCell(colIndex + 1).value;

      // Format as numbers with two decimal places
      const formatAsNumber = (cell, value) => {
        if (value !== null && !isNaN(value) && rowNumber > 2) {
          cell.value = Number(value); // Format the value as a number
          cell.numFmt = "#,##0"; // Format the number with two decimal places
        }
      };

      const cell = row.getCell(colIndex + 1);
      formatAsNumber(cell, cellValue);

      if (cellValue !== null && !isNaN(cellValue)) {
        const color =
          cellValue > 0 ? NewTheme.greencolorExcel : NewTheme.redcolorExcel; // Green for positive, Red for negative

        cell.font = {
          color: { argb: color },
          bold: true,
        };
      }
    });

    // Add empty rows between data and footer
    // for (let i = 0; i < 2; i++) {
    //   worksheet.addRow([]);
    // }
    const totalAmount = billSend.reduce((sum, item) => {
      return sum + (parseFloat(item.amount) || 0); // Convert amount to a float and handle any non-numeric values
    }, 0);

    const footerDisplayNames = ["", "TOTAL", "NET P/L", parseInt(totalAmount)];
    const footer = worksheet.addRow(footerDisplayNames);
    const totalAmountColor =
      totalAmount > 0 ? NewTheme.greencolorExcel : NewTheme.redcolorExcel; // Green for positive, Red for negative

    footer.eachCell((cell, colNumber) => {
      if (colNumber === 4) {
        // Assuming the totalAmount is in the 4th column
        const formattedAmount = parseInt(totalAmount);
        cell.value = formattedAmount;
        cell.numFmt = "#,##0"; // Excel number format for thousands separator and two decimal places
        cell.font = {
          bold: true,
          color: {
            argb: totalAmountColor,
          }, // Green for positive, Red for negative
        };
      } else {
        // Set font properties for non-totalAmount columns
        cell.font = {
          bold: true,
        };
      }
      // Set the background color to orange
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FEBA4F" }, // Orange background color (ARGB format)
      };
      cell.border = {
        top: { style: "thin", color: { argb: "FF808080" } }, // Gray border color (ARGB format)
        left: { style: "thin", color: { argb: "FF808080" } },
        bottom: { style: "thin", color: { argb: "FF808080" } },
        right: { style: "thin", color: { argb: "FF808080" } },
      };
      worksheet.getColumn(colNumber).alignment = {
        horizontal: "center",
        vertical: "middle", // Optional: Aligns text vertically in the middle
      };
    });

    const distinctAliasNames = [
      ...new Set(billSend.map((item) => item.alias_name)),
    ];
    const aliasNameSums = distinctAliasNames.map((alias) => {
      const totalAmount = billSend
        .filter((item) => item.alias_name === alias) // Filter billSend for the current alias
        .reduce((sum, item) => sum + (parseFloat(item.amount) || 0), 0); // Sum the 'amount' for the current alias

      return { alias, totalAmount };
    });
    let maxAmountLength = Math.max(
      ...aliasNameSums.map((item) => item.totalAmount.toFixed(2).length),
      "Total Amount".length // Include the header length
    );

    // Start inserting rows at E2
    let currentRowNumber = 2;

    aliasNameSums.forEach(({ alias, totalAmount }) => {
      const row = worksheet.getRow(currentRowNumber); // Get the row object for the current row number

      // Set the values starting from column E (column index 5)
      row.getCell(5).value = alias; // Cell E (alias name)
      row.getCell(6).value = parseFloat(totalAmount.toFixed(2)); // Cell F (total amount formatted as number)

      // Apply formatting to each cell from E onwards
      row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        if (colNumber >= 5) {
          // Only apply formatting to columns E and beyond
          cell.font = { bold: true }; // Style as needed
          cell.alignment = { horizontal: "center", vertical: "middle" }; // Center align
          cell.border = {
            top: { style: "thin", color: { argb: "FF808080" } },
            left: { style: "thin", color: { argb: "FF808080" } },
            bottom: { style: "thin", color: { argb: "FF808080" } },
            right: { style: "thin", color: { argb: "FF808080" } },
          };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FEBA4F" },
          };

          if (colNumber === 6) {
            // Apply number format for the totalAmount column (column F)
            cell.numFmt = "#,##0.00"; // Excel number format for thousands separator and two decimal places
          }
        }
      });

      currentRowNumber++; // Move to the next row
    });

    // Add two total rows after the aliasNameSums data
    const totalRow1 = worksheet.getRow(currentRowNumber++);
    totalRow1.getCell(5).value = "Total"; // Label for the first total row
    totalRow1.getCell(6).value = parseInt(totalAmount); // Sum of all totalAmounts
    totalRow1.eachCell({ includeEmpty: true }, (cell, colNumber) => {
      if (colNumber >= 5) {
        cell.font = { bold: true }; // Make the total bold
        cell.alignment = { horizontal: "center", vertical: "middle" }; // Center align
        cell.border = {
          top: { style: "thin", color: { argb: "FF808080" } },
          left: { style: "thin", color: { argb: "FF808080" } },
          bottom: { style: "thin", color: { argb: "FF808080" } },
          right: { style: "thin", color: { argb: "FF808080" } },
        };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "60A3D9" },
        };
        if (colNumber === 6) {
          // Apply number format for the totalAmount column (column F)
          cell.numFmt = "#,##0.00"; // Excel number format for thousands separator and two decimal places
        }
      }
    });

    worksheet.getColumn(6).width = maxAmountLength + 2;

    var fileName = "GROUP SHEET - Transaction";

    // Create Excel file and trigger download
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAsExcelFile(buffer, fileName);
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const filterbar = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 10,
        fontWeight: "bold",
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 20,
          gap: 10,
        }}
      >
        <InputText
          style={{ width: 140 }}
          type="search"
          value={value || ""}
          onChange={(e) => onGlobalFilterChange(e)}
          placeholder="Search"
        />
        <Button
          type="button"
          icon="pi pi-plus"
          severity="warning"
          label={"Add"}
          style={{
            width: 100,
            height: 25,
            paddingLeft: 10,
            paddingRight: 10,
            fontSize: 12,
          }}
          onClick={() => show()}
          data-pr-tooltip="PDF"
        />
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 10,
          }}
        >
          <Button
            type="button"
            label="XLS"
            severity="primary"
            style={{
              width: 100,
              height: 25,
              paddingLeft: 10,
              paddingRight: 10,
              fontSize: 12,
            }}
            onClick={exportExcel}
            data-pr-tooltip="EXCEL"
          />
        </div>
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 10,
          }}
        >
          <Button
            type="button"
            label="Clear"
            severity="danger"
            style={{
              width: 100,
              height: 25,
              paddingLeft: 10,
              paddingRight: 10,
              fontSize: 12,
            }}
            onClick={clearGroupData}
            data-pr-tooltip="EXCEL"
          />
        </div>
      </div>
    </div>
  );

  const [visible, setVisible] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [position, setPosition] = useState("top");

  const show = (position) => {
    setVisible(true);
  };

  const showEdit = (position_id) => {
    setBillSendId(position_id);
    setVisibleEdit(true);
  };

  return (
    <div
      className="card"
      style={{
        paddingTop: 0,
        overflow: "hidden",
      }}
    >
      <TopbarMost name="Group Bill" cmp={filterbar} />

      <div id={"stick"} style={{ position: "relative" }}>
        <DataTable
          removableSort
          stripedRows
          showGridlines
          scrollable
          scrollHeight={height - 150}
          size="small"
          // filterDisplay="row"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          value={billSend}
          style={{ fontWeight: "bold", fontSize: 14 }}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginator
          rows={10}
          globalFilterFields={["alias_name", "group_name", "group_detail"]}
          loading={loading}
          filters={filters}
          onFilter={(e) => setFilters(e.filters)}
          rowsPerPageOptions={[10, 25, 50, 100]}
        >
          <Column
            field="alias_name"
            sortable
            showFilterMenu={false}
            header="Alias Name"
            body={(rowData) => <div>{rowData?.alias_name}</div>}
          />
          <Column
            field="group_name"
            sortable
            showFilterMenu={false}
            header="Group Name"
            body={(rowData) => <div>{rowData?.group_name}</div>}
          />
          <Column
            field="group_detail"
            sortable
            showFilterMenu={false}
            header="Group Detail"
            body={(rowData) => <div>{rowData?.group_detail}</div>}
          />
          <Column
            field="amount"
            sortable
            showFilterMenu={false}
            header="Amount"
            body={(rowData) => (
              <div>
                <NumberFormatIn value={rowData?.amount} />
              </div>
            )}
          />

          <Column
            field="date_created"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header=""
            body={(rowData) => (
              <div style={{ display: "flex", gap: 5 }}>
                <Button
                  type="button"
                  icon="pi pi-pencil"
                  severity="info"
                  style={{ height: 25, width: 25 }}
                  onClick={() => showEdit(rowData.id)}
                />
                <Button
                  type="button"
                  icon="pi pi-trash"
                  severity="danger"
                  style={{ height: 25, width: 25 }}
                  onClick={() => deleteEntry(rowData.id)}
                />
              </div>
            )}
          />
        </DataTable>
      </div>
      {visible && (
        <AddModal
          key={new Date()}
          setVisible={() => setVisible(false)}
          position={position}
          reload={() => load_group_bill()}
          visible={visible}
        />
      )}
      {visibleEdit && (
        <EditModal
          key={new Date()}
          setVisible={() => {
            setVisibleEdit(false);
          }}
          position={position}
          visible={visibleEdit}
          billSendId={billSendId}
          reload={() => load_group_bill()}
        />
      )}
    </div>
  );
}
